<template>
  <div id="exams">
    <section
      class="page-header"
      style="
        background-image: url('/imgs/exams-01.png');
        background-position: top center;
      "
    >
      <div class="caption">
        <h4>{{ $lang("title") }}</h4>
        <i class="isologo"></i>
        <h1>{{ $lang("subtitle") }}</h1>
      </div>
    </section>

    <section class="bg-tertiary py-6 text-center text-white decoration-3">
      <div class="container">
        <p>
          {{ $lang("desc") }}
        </p>
      </div>
    </section>

    <section class="py-6">
      <div class="container">
        <h4 class="text-primary mb-5 text-center">
          {{ $lang("features.title") }}
        </h4>

        <div
          class="card card-medium-tint d-flex align-items-stretch mb-6"
          v-for="(i, index) of $lang('features.items')"
          :key="index"
        >
          <div
            class="card-header bg-white p-5 d-flex align-items-center justify-content-center"
          >
            <img :src="i.logo" alt="" />
          </div>
          <div class="card-body d-flex align-items-center p-5 text-medium">
            <div v-html="i.desc" />
          </div>
        </div>

        <button
          class="btn btn-primary px-5 mx-auto mt-6"
          @click="$router.push('/contact-us')"
        >
          {{ $lang("features.button") }}
        </button>
      </div>
    </section>
  </div>
</template>
